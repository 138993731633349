import clsx from "clsx";
import React, { ReactNode } from "react";
import { useInView } from "react-intersection-observer";

import BodyText from "@components/BodyText";
import ContentBlocks from "@components/ContentBlocks";
import ContentImage from "@components/ContentImage";
import Embed from "@components/Embed";
import FileDownloadButtons from "@components/FileDownloadButtons";
import HeadingTag from "@components/HeadingTag";
import Quotation from "@components/Quotation";
import Spacer from "@components/Spacer";
import VideoEmbed from "@components/VideoEmbed";
import Accordion from "@sharedComponents/Accordion";
import ButtonBlock from "@sharedComponents/ButtonBlock";

const Component = ({ section }: { section: any }) => {
	let image;
	let imageBlur;

	switch (section.typeHandle) {
		case "heading":
			if (!section.heading) return null;
			return (
				<div className={clsx("ContentSections__heading", "max-w-3xl")}>
					<HeadingTag
						tag={section.headingTag}
						className={clsx({
							"text-4xl 3xl:text-5xl":
								!section.headingSize || section.headingSize === "xl",
							"text-3xl": section.headingSize === "lg",
							"text-2xl": section.headingSize === "md",
							"text-xl": section.headingSize === "sm"
						})}
					>
						{section.heading}
					</HeadingTag>
				</div>
			);

		case "image":
			image = section.image[0];
			imageBlur = section.imageBlur[0];
			if (!image) return null;
			return (
				<ContentImage
					image={image}
					imageBlur={imageBlur}
					caption={section.caption}
					captionPosition={section.captionPosition}
				/>
			);

		case "bodyText":
			if (!section.bodyText) return null;
			return <BodyText text={section.bodyText} />;

		case "contentBlocks":
			if (!section.contentBlocks) return null;
			return (
				<ContentBlocks
					blocks={section.contentBlocks}
					layout={section.layout}
					legacyBackgroundColor={section.legacyBackgroundColor}
					textColor={section.textColor}
          columns={parseInt(section.columns)}
          horizontalPadding={section.horizontalPadding}
				/>
			);

		case "videoEmbed":
			if (!section.videoUrl) return null;
			const backgroundImage = section.backgroundImage[0];
			const backgroundImageBlur = section.backgroundImageBlur[0];

			return (
				<VideoEmbed
					url={section.videoUrl}
					transcript={section.videoTranscript}
					transcriptTitle={section.transcriptTitle}
					transcriptBackgroundColor={section.backgroundColor}
					backgroundImage={backgroundImage}
					backgroundImageBlur={backgroundImageBlur}
					videoStyle={section.videoStyle}
				/>
			);

		case "embed":
			return <Embed embedCode={section.embedCode} />;

		case "spacer":
			return (
				<Spacer
					borderColor={section.legacyBackgroundColor}
					size={section.size}
				/>
			);

		case "fileDownloadButtons":
			return (
				<FileDownloadButtons
					files={section.downloadableFiles}
					//width={"lg"}
					alignment={section.horizontalAlignment}
				/>
			);

		case "accordion":
			return <Accordion accordionContent={section.accordionContent} />;

		case "button":
			if (!section.target) return null;
			return (
				<ButtonBlock
					target={section.target}
					verticalPadding={section.verticalPadding}
					buttonBackgroundColor={section.buttonBackgroundColor}
					buttonTextColor={section.buttonTextColor}
					horizontalAlignment={section.horizontalAlignment}
					eventDescription={section.eventDescription}
				/>
			);

    case "quotation":
    return (
      <Quotation 
        quotation={section.quotation} 
        quotationStyle={section.quotationStyle} 
        attribution={section.attribution} 
        role={section.role} 
      />
    );

		default:
			return <div>{section.typeHandle}</div>;
	}
};

interface ISectionProps {
	children: ReactNode;
	typeHandle: string;
}

const Section = ({ children, typeHandle }: ISectionProps): JSX.Element => {
	const { ref, inView } = useInView();

	const className = clsx(
		"ContentSection",
		`ContentSection--${typeHandle}`,
		"transition-opacity duration-500 ease-out",
		{
			"opacity-0": !inView,
			"opacity-100": inView
		}
	);

	return (
		<div ref={ref} className={className}>
			{children}
		</div>
	);
};

interface IProps {
	sections: any[];
}

const ContentSections = ({ sections }: IProps): JSX.Element => {
	return (
		<div className={clsx("ContentSections", "space-y-7.5")}>
			{sections.map((section, index) => {
				return (
					<div
						key={section.id}
						className={clsx(
							"ContentSections__section",
							`ContentSections__section--${section.typeHandle}`
							// { "xl:-ml-15 xl:-mr-15": section.typeHandle === "videoEmbed" }
						)}
					>
						<Section typeHandle={section.typeHandle}>
							<Component section={section} />
						</Section>
					</div>
				);
			})}
		</div>
	);
};

export default ContentSections;
